import React from 'react'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

class FourOhFour extends React.Component {
  render() {
    return (
      <Layout>
        <div class="four-o-four">
          <img src="/failbag.png" />
          <h1>404 - Nothing to see here</h1>
          <p>The Scumbags running our web site failed to locate the page you requested.</p>

          <p><a href="/">Take me home!</a></p>
        </div>
      </Layout>
    )
  }
}

export default FourOhFour