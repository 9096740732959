import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'


export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allContentfulGame(sort: { fields: [publishDate], order: DESC }) {
          edges {
            node {
              name
              publishDate(formatString: "MMMM Do, YYYY")
              slug
              color
            }
          }
        }
        allContentfulSite(filter: { contentful_id: { eq: "3U3xgdVQHCikAG4sQc2Mai" } }) {
          edges {
            node {
              logo {
                fixed(width: 100, height: 26) {
                width
                height
                base64
                src
                srcSet
                }
              }
              title
              subtitle
              twitter
              facebook
              youtube
              instagram
            }
          }
        }
      }
    `}
    render={data => (
      <footer>
        <div className="container">
          <Link to="/" className="logo"><Img alt="" fixed={data.allContentfulSite.edges[0].node.logo.fixed} /></Link>
          <ul>
            <li className="social"><a href="/" className="fas fa-star first last"></a></li>
            {data.allContentfulGame.edges.map(({ node }) => {
              return (
                <li className="mobile-li" key={node.slug}>
                  <Link to={`/games/${node.slug}`}>{node.name}</Link>
                </li>
              )
            })}
            <li><a href="/blog/">Blog</a></li>
            <li><a href="/join/">Join</a></li>
            <li><a href="/team/">Team</a></li>
            <li><a href="/contact/">Contact</a></li>
            <li><a href="http://store.supergiantgames.com">Store</a></li>
            <li><a href="http://store.supergiantgames.com/pages/terms-of-use">Terms of Use</a></li>
            <li><a href="http://store.supergiantgames.com/pages/privacy-policy">Privacy Policy</a></li>
            <li><a href={`/feed.xml`} className="fas fa-rss first"></a></li>
            <li><a href={`http://www.twitter.com/${data.allContentfulSite.edges[0].node.twitter}`} className="fab fa-twitter"></a></li>
            <li><a href={`http://www.facebook.com/${data.allContentfulSite.edges[0].node.facebook}`} className="fab fa-facebook"></a></li>
            <li><a href={`http://www.youtube.com/user/${data.allContentfulSite.edges[0].node.youtube}`} className="fab fa-youtube"></a></li>
            <li><a href={`http://www.instagram.com/${data.allContentfulSite.edges[0].node.instagram}`} className="fab fa-instagram last"></a></li>
            <li><a href={`http://www.discord.gg/supergiant`} className="fab fa-discord last"></a></li>
          </ul>
        </div>
        <div className="newsletter">
          <div className="container">
            <span className="text fas fa-envelope"/><span className="text"> Join the <a href="/join/">Supergiant Games Mailing List</a> </span>
          </div>
        </div>
      </footer>
    )}
  />
)
