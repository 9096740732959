import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import { Location } from '@reach/router'
import Img from 'gatsby-image'


export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allContentfulGame(sort: { fields: [publishDate], order: DESC }) {
          edges {
            node {
              name
              publishDate(formatString: "MMMM Do, YYYY")
              slug
              color
            }
          }
        }
        allContentfulSite(filter: { contentful_id: { eq: "3U3xgdVQHCikAG4sQc2Mai" } }) {
          edges {
            node {
              logo {
                fixed(width: 250, height: 96) {
                width
                height
                base64
                src
                srcSet
                }
              }
              title
              subtitle
              twitter
              facebook
              youtube
              instagram
              discord
            }
          }
        }
      }
    `}
    render={data => (
      <NavBar data={data} />
    )}
  />
)

class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { menuActive: false }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => {
      return {
        menuActive: !prevState.menuActive
      }
    })
  }

  render() {
    const { data } = this.props;
    const navWrapperClasses = "nav-wrap" + (this.state.menuActive ? " active" : "");

    return (
      <nav>
        <div className="container">
          <Link to="/" className="logo"><Img alt="" fixed={data.allContentfulSite.edges[0].node.logo.fixed} /></Link>
          <div className="nav-mobile" onClick={this.handleClick}>
            <span className="fas fa-bars">&nbsp;</span>
          </div>
          <div className={navWrapperClasses}>
            <ul className="nav-left">
              <li className="moblie-li mobile-show"><Link to="/">Home</Link></li>
              <li className="social"><a href="/" className="fas fa-star first last"></a></li>
              {data.allContentfulGame.edges.map(({ node }) => {
                return (
                  <li className="mobile-li" key={node.slug}>
                    <Link to={`/games/${node.slug}`} activeClassName="active" className="game" style={{ color: node.color }}>{node.name}</Link>
                  </li>
                )
              })}
              <li className="moblie-li"><Link to="/blog/">Blog</Link></li>
              <li className="mobile-li"><Link to="/join/">Join</Link></li>
              <li className="mobile-li"><Link to="/team/">Team</Link></li>
              <li className="mobile-li"><Link to="/contact/">Contact</Link></li>
              <li className="mobile-li"><a href="https://supergiant.games/merch"><b>Merch</b></a></li>
            </ul>
            <ul className="nav-right">
              <li className="social"><a href={`http://www.twitter.com/${data.allContentfulSite.edges[0].node.twitter}`} className="fab fa-twitter first"></a></li>
              <li className="social"><a href={`http://www.facebook.com/${data.allContentfulSite.edges[0].node.facebook}`} className="fab fa-facebook"></a></li>
              <li className="social"><a href={`http://www.youtube.com/user/${data.allContentfulSite.edges[0].node.youtube}`} className="fab fa-youtube last"></a></li>
              <li className="social"><a href={`http://www.instagram.com/${data.allContentfulSite.edges[0].node.instagram}`} className="fab fa-instagram last"></a></li>
              <li className="social"><a href={`https://discord.gg/${data.allContentfulSite.edges[0].node.discord}`} className="fab fa-discord last"></a></li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
