import React from 'react'
import { Link } from 'gatsby'
import './base.css'
import Container from './container'
import Navigation from './navigation'
import Footer from './footer'
import Helmet from 'react-helmet'

class Template extends React.Component {
  render() {
    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container>
        <Helmet title={'Supergiant Games'}>
          <meta name="description" content="Supergiant Games is a small independent game development studio in San Francisco, known for its award-winning titles Hades, Pyre, Transistor, and Bastion." />
          <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/jquery.slick/1.3.7/slick.css"/>
          <link rel="stylesheet" href="https://use.typekit.net/tbb7tun.css"></link>
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.9.0/css/all.css" integrity="sha384-i1LQnF23gykqWXg6jxC2ZbCbUMxyw5gLZY6UiUS98LYV5unm8GWmfkIS6jqJfb4E" crossorigin="anonymous"></link>
          <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
          <script type='text/javascript'>{`(function ($) { window.fnames = new Array()}; window.ftypes = new Array(); fnames[0] = 'EMAIL'; ftypes[0] = 'email'; fnames[1] = 'FNAME'; ftypes[1] = 'text'; fnames[4] = 'MMERGE4'; ftypes[4] = 'text'; fnames[3] = 'MMERGE3'; ftypes[3] = 'text'; fnames[2] = 'COMPANY'; ftypes[2] = 'text'; fnames[5] = 'MMERGE5'; ftypes[5] = 'address'; }(jQuery)); var $mcj = jQuery.noConflict(true);`}</script>
        </Helmet>
        <Navigation />
        {children}
        <Footer />
      </Container>
    )
  }
}

export default Template
